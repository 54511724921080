.t-errors {
    margin-top: rem(48);
    padding-left: rem(16);
    padding-right: rem(16);
    @include adjust-flex(center, center);
    flex-direction: column;

    @include mobile-down {
        margin-top: rem(24);
    }

    &_image {
        width: 100%;
        max-width: rem(295);
        margin-top: rem(-32);
        @include mobile-down {
            max-width: rem(200);
        }
    }

    &_titleWrapper {
        margin-top: rem(36);
        text-align: center;
        background-image: url('~assets/images/error_bg.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        padding: rem(40);

        @include mobile-down {
            margin-top: rem(15);
        }
    }

    &_title {
        @include font-base(70, 74);
        font-weight: 700;
        @include mobile-down {
            @include font-base(40, 48);
        }
    }

    &_button {
        margin-top: rem(16);
        display: flex;
        justify-content: center;
        @include mobile-down {
            margin-top: rem(15);
        }
    }

    &_content {
        margin-left: rem(20);
        @include mobile-down {
            margin-left: rem(10);
        }
    }

    &_back {
        &-text {
            text-decoration: underline !important;
        }
    }
}
