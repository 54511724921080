.o-newsCard {
    &_title {
        margin-top: rem(15);
        padding: 0 rem(10);
        @include text-overflow;
    };

    &_img {
        border-radius: rem(15);
        overflow: hidden;
        width: 100%;
    }
}
