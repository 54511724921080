.t-homeIntroduction {
    background: linear-gradient(281.66deg, #ffcda1 0.41%, #ffeedf 56.89%);
    position: relative;
    
    &::after {
        position: absolute;
        content: '';
        width: rem(448);
        height: rem(196);
        bottom: 0;
        right: 0;
        background-image: url('~assets/images/heart-beat-orange.png');
        background-repeat: no-repeat;
        background-size: contain;

        @include desktop-down {
            width: rem(336);
            height: rem(147);
        }

        @include tablet-down {
            width: rem(224);
            height: rem(98);
        }
    }

    &_content {
        font-weight: 500;
        text-align: center;
        color: $smoky-black;
        @include font-base(24, 38);

        @include desktop-down {
            @include font-base(20, 34);
        }

        @include desktop-down {
            @include font-base(16, 24);
        }
    }

    &_videoList {
        position: relative;
        z-index: 2;
        
        @include mobile-up {
            @include adjust-flex(space-between);
        }
        
        margin-top: rem(60);

        @include desktop-down {
            margin-top: rem(30);
        }

        @include mobile-down {
            margin-top: rem(20);
        }
    }

    &_seeMoreBtn {
        @include adjust-flex;
        width: fit-content;
        background-color: $french-plum;
        padding: rem(14) rem(22);
        border-radius: rem(32);
        margin: rem(40) auto 0;
        position: relative;
        z-index: 2;

        .a-icon-nextArrow {
            margin-left: rem(10);
            transition: all 0.2s ease-in-out;
        }

        &:hover {
            .a-icon-nextArrow {
                transform: translateX(10px);
            }
        }

        @include tablet-down {
            margin-top: rem(24);
        }

        @include mobile-down {
            padding-top: rem(8);
            padding-bottom: rem(8);
            width: 100%;
        }
    }

    &_item {
        @include mobile-up {
            flex: 1;
        }

        max-width: rem(620);

        &:last-child {
            margin-left: rem(40);

            @include tablet-down {
                margin-left: rem(20);
            }

            @include mobile-down {
                margin-left: auto;
                margin-top: rem(16);
            }
        }

        @include mobile-down {
            max-width: 70%;
            margin-left: auto;
            margin-right: auto;
        }

        @include small-mobile {
            max-width: 100%;
        }
    }
}
