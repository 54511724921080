.o-seriesCard {
    border-radius: rem(16);
    background-color: $anti-flash-white;
    padding: rem(30) rem(15) rem(20) rem(40);
    width: 100%;
    height: 100%;

    @include tablet-down {
        padding: rem(24) rem(8) rem(24) rem(24);
    }
    @include mobile-down {
        padding: rem(16) rem(8) rem(16) rem(16);
    }

    &_wrapper {
        overflow-y: auto;
        width: 100%;
        height: 100%;
        @include scroll-bars(4);
        padding-right: rem(25);
        @include tablet-down {
            padding-right: rem(16);
        }
        @include mobile-down {
            padding-right: rem(8);
        }
    }

    &_content {
        margin-top: rem(12);

        @include mobile-down {
            margin-top: rem(6);
        }
    }

    &_image {
        display: block;
        width: 57%;
        margin: rem(42) auto 0;
        object-fit: contain;
        @include tablet-down {
            margin-top: rem(32);
        }
        @include mobile-down {
            margin-top: rem(24);
        }
    }
}
