.t-aboutIntroduction {
    position: relative;
    background: $linear-about-section;

    &::before {
        content: '';
        position: absolute;
        background-image: url('~assets/images/heart-beat.png');
        background-size: cover;
        max-width: rem(448);
        width: 100%;
        max-height: rem(196);
        height: 100%;
        bottom: 0;
        right: 0;

        @include mobile-down {
            max-width: rem(224);
            max-height: rem(98);
        }
    }

    &_wrapper {
        @include adjust-flex;
        flex-wrap: wrap;
    }

    &_left,
    &_right {
        flex-basis: calc(50% - 61px);

        @include tablet-down {
            flex-basis: 50%;
        }

        @include mobile-down {
            flex-basis: 100%;
        }
    }

    &_content {
        margin-top: rem(30);
    }

    &_btn {
        flex-basis: calc(100% - 123px);
        margin-top: rem(40);

        @include tablet-down {
            flex-basis: 100%;
        }
        
        button {
            max-width: fit-content;
            height: rem(56);

            @include mobile-down {
                margin-left: auto;
                margin-right: auto;
                height: rem(48);
            }
        }

        .a-icon-rightArrowCircle {
            margin-left: rem(10);
        }
    }

    &_right {
        padding-left: rem(32);
        @include mobile-down {
            padding-left: 0;
            margin: rem(24) auto 0;
            max-width: 60%;
        }

        &_img {
            max-width: rem(448);
            margin-left: auto;
            @include mobile-down {
                margin-right: auto;
            }
        }
    }
}
