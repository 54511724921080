.p-exam {
    &_breadcrumbs {
        margin-top: rem(16);
        margin-bottom: rem(20);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &_wrapper {
        position: relative;
        border: 1px solid $platinum;
        padding-left: rem(115);
        padding-right: rem(115);

        @include tablet-down {
            padding-left: rem(80);
            padding-right: rem(80);
        }
        @include mobile-down {
            padding-left: rem(16);
            padding-right: rem(16);
        }
    }

    &_heading {
        padding-top: rem(30);
        padding-bottom: rem(30);
        border-bottom: 1px solid $platinum;
        @include tablet-down {
            padding-top: rem(24);
            padding-bottom: rem(24);
        }
        @include mobile-down {
            padding-top: rem(16);
            padding-bottom: rem(16);
        }
    }

    &_exit {
        position: absolute;
        top: rem(30);
        right: rem(30);
        width: rem(30);
        height: rem(30);

        @include mobile-down {
            top: rem(16);
            right: rem(16);
        }
    }

    &_content {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        margin-top: rem(16);
        margin-bottom: rem(60);
    }

    &_controls {
        width: 100%;
        margin-top: rem(40);
        margin-bottom: rem(40);
        @include tablet-down {
            order: 2;
        }
    }

    &_series {
        width: 46%;
        flex-shrink: 0;
        padding-right: rem(30);
        border-right: 1px solid $platinum;
        height: rem(560);
        @include tablet-down {
            width: 100%;
            order: 1;
            padding-right: 0;
            border-right: 0;
            height: rem(400);
        }
    }

    &_question {
        width: 54%;
        padding-left: rem(32);
        @include tablet-down {
            width: 100%;
            order: 3;
            padding-left: 0;
        }

        .a-error_text {
            font-size: rem(12);
        }
    }

    &_button {
        margin-top: rem(15);
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .a-button {
            max-width: rem(120);
            margin-left: rem(20);
        }
    }

    &_btnConfirm {
        max-width: rem(140);
        margin: rem(30) auto 0;
    }

    &_result {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
