.t-accordion {
    border-bottom: 1px solid $isabelline;
    margin-bottom: rem(20);

    &_sumary {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: rem(20);
        user-select: none; /* standard syntax */
        -webkit-user-select: none; /* webkit (safari, chrome) browsers */
        -moz-user-select: none; /* mozilla browsers */
        -khtml-user-select: none; /* webkit (konqueror) browsers */
        -ms-user-select: none; /* IE10+ */
    }

    &-open {
        .t-accordion_details {
            grid-template-rows: 1fr;
        }
    }

    &_details {
        display: grid;
        grid-template-rows: 0fr;
        margin-left: rem(47);
        transition: 250ms grid-template-rows ease;

        div {
            overflow: hidden;
        }
    }

    &_indicator {
        position: relative;
        aspect-ratio: 1;
        min-width: rem(32);
        min-height: auto;
        border-radius: rem(7);
        border: 1px solid $gray;
        margin-right: rem(15);

        span {
            display: block;
            width: rem(16);
            height: rem(4);
            background-color: $cadmium-orange;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            transition: 0.2s;

            &:first-child { 
                transform: translateX(-50%) translateY(-50%) rotate(90deg);
            }
        }

        &-open {
            span {
                &:first-child {
                    transform: translateX(-50%) translateY(-50%) rotate(0deg);
                }
            }
        }
    }

    &_title {
        transition: 0.2s;
    }

    &_content {
        padding: rem(10) 0 rem(10) rem(15);
        margin-bottom: rem(29);
        border-left: rem(2) solid $cadmium-orange;
    }
}
