.o-xResult {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: rem(460);

    &_title {
        color: $resolution-blue;
        margin-bottom: rem(10);
    }

    &_desc {
        margin-bottom: rem(10);
    }

    &_score {
        font-weight: 700;
        margin-bottom: rem(10);

        span {
            font-size: rem(24);

            &:first-child { 
                font-size: rem(36);
            }

            &:nth-child(2) {
                font-size: rem(36);
                color: $dim-gray;
            }

            &:nth-child(3) {
                color: $dim-gray;
            }
        }

        &-pass {
            color: $forest-green;
        }

        &-fail {
            color: $international-orange;
        }
    }

    &_icon {
        width: rem(64);
        margin: auto;
        margin-bottom: rem(20);
    }

    &_info {
        background-color: $anti-flash-white;
        border-radius: rem(15);
        padding: rem(20);
        width: 100%;
        margin-bottom: rem(40);
    }

    &_result {
        border-bottom: 1px solid $isabelline;
        padding-bottom: rem(12);
        margin-bottom: rem(10);

        span {
            font-size: rem(24);
            font-weight: 700;
            margin-left: rem(9);
        }

        &-pass {
            color: $forest-green;
        }

        &-fail {
            color: $international-orange;
        }
    }

    &_buttons {
        display: flex;

        .a-button {
            width: rem(180);
            text-wrap: nowrap;

            &:first-child {
                margin-right: rem(20);

                @include mobile-down {
                    margin-right: 0;
                    margin-bottom: rem(16);
                }
            }

            @include mobile-down {
                width: 100%;
            }
        }

        @include mobile-down {
            flex-direction: column;
            width: 100%;
        }
    }
}
