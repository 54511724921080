.t-changePassword {
    &_input {
        &_container {
            position: relative;
            margin-bottom: rem(15);

            &:last-child {
                margin-bottom: 0;
            }
        }

        @include mobile-down {
            width: 100%;
        }
    }

    &_submit {
        max-width: rem(160);
        margin-top: rem(30);
        margin-left: auto;
        margin-right: auto;
    }

    &_error {
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translateY(rem(17));
    }
}

.o-modal_body {
    padding: rem(17) rem(38) rem(5) rem(38) !important;
}
