.a-checkbox {
    $root: &;

    label {
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    input {
        /* removing default appearance */
        -webkit-appearance: none;
        appearance: none;
        /* creating a custom design */
        cursor: pointer;
        border-radius: rem(4);
        outline: rem(1) solid $black;
        outline-offset: rem(1);
        aspect-ratio: 1;
        width: rem(14);
        height: auto;
        padding: rem(2);
        margin-right: rem(10);

        &:checked {
            background-color: $resolution-blue;
        }

        &:disabled {
            pointer-events: none;
            background-color: transparent;
            border-color: $platinum;
            outline-color: $platinum;
        }
    }

    &-primary {
        input {
            &:checked {
                background-color: $resolution-blue-auth;
            }
        }
    }

    &-disabled {
        p,
        span {
            color: $platinum;
        }

        label,
        input {
            cursor: default;
        }
    }
}
