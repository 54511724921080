.o-footer {
    background-color: $resolution-blue;

    &-primary {
        background-color: $french-plum;
    }

    .o-container {
        @include adjust-flex;
        padding-top: rem(44);
        padding-bottom: rem(44);

        @include small-mobile {
            padding-top: rem(22);
            padding-bottom: rem(22);
            flex-direction: column;
        }
    }

    &_information {
        @include adjust-flex;

        &_detail {
            margin-left: rem(24);
        }

        &_item {
            display: flex;
            align-items: center;

            &:not(:first-child) {
                margin-top: rem(10);

                @include small-mobile {
                    margin-top: rem(6);
                }
            }
        }

        &_name {
            margin-left: rem(10);
        }

        &_image {
            object-fit: contain;
            width: 100%;
            max-width: rem(110);

            @include small-mobile {
                max-width: rem(80);
            }
        }
    }

    &_cooperation {
        margin-left: rem(78);

        &_image {
            object-fit: contain;
            width: 100%;
            max-width: rem(180);

            @include small-mobile {
                max-width: rem(120);
            }
        }

        @include small-mobile {
            margin-top: rem(16);
            margin-left: 0;
        }
    }
}
