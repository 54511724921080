.o-coursesIntroduce {
    background: linear-gradient(281.93deg, #fff2f8 56.72%, #d691b1 100%);
    @include adjust-flex;
    border-radius: rem(32);
    padding: rem(64);

    @include tablet-down {
        flex-direction: column;
        padding: rem(48);
    }

    @include mobile-down {
        padding: rem(32);
    }

    @include small-mobile {
        border-radius: rem(16);
        padding: rem(16) rem(16) rem(48);
    }

    &_content {
        @include tablet-up {
            margin-left: rem(100);
            max-width: rem(508);
        }
        @include tablet-down {
            margin-top: rem(16);
        }
    }

    &_image {
        width: 100%;
        max-width: rem(500);

        @include tablet-down {
            max-width: rem(400);
            margin-left: auto;
            margin-right: auto;
        }

        @include mobile-down {
            max-width: rem(300);
        }

        @include small-mobile {
            max-width: rem(200);
        }
    }

    &_title {
        color: $jet;
        @include font-base(16, 32);
        font-weight: 700;

        @include mobile-down {
            text-align: center;
        }
    }

    &_heading {
        margin-top: rem(10);
        margin-bottom: rem(8);

        @include mobile-down {
            text-align: center;
            margin-top: 0;
        }
    }

    &_descript {
        margin-top: rem(8);
        margin-bottom: rem(20);
        font-weight: 500;
    }

    &_detailBtn {
        display: inline-block;
        width: fit-content;
        background-color: $french-plum;
        padding: rem(14) rem(22);
        border-radius: rem(32);
        @include adjust-flex;

        .a-icon-nextArrow {
            margin-left: rem(10);
            transition: all 0.2s ease-in-out;
        }

        &:hover {
            .a-icon-nextArrow {
                transform: translateX(10px);
            }
        }

        @include tablet-down {
            margin-right: auto;
        }

        @include mobile-down {
            padding-top: rem(8);
            padding-bottom: rem(8);
            width: 100%;
        }
    }
}
