.o-container {
    @include max-desktop-down {
        max-width: 1366px;
        padding-left: rem(37);
        padding-right: rem(37);
    }

    @include tablet-desktop {
        padding-left: rem(24);
        padding-right: rem(24);
    }
    @include mobile-down {
        padding-left: rem(16);
        padding-right: rem(16);
    }
}
