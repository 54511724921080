.m-breadcrumbs {
    display: flex;
    cursor: default;
    
    &-path {
        cursor: pointer;

        transition: 0.25s;

        &-wrapper {
            display: flex;
            list-style-type: none;
        }

        &:hover {
            color: $raisin-black;
        }
    }

    &-slash {
        margin: 0 rem(4);
    }
}
