.a-inputCustom {
    $root: &;
    position: relative;
    width: 100%;

    &_input {
        padding: rem(12) !important;
        height: rem(40);
        border-radius: rem(6) !important;
        border-color: $light-cyan !important;

        input {
            padding: 0 !important;
            color: $raisin-black;
            @include font-base(12, 18);

            &::placeholder {
                color: $battleship-grey;
            }


            &:disabled {
                color: $battleship-grey;
            }
        }

        &-error {
            border-color: $fire-engine-red !important;
        }
    }

    &-icon {
        position: absolute;
        width: rem(40);
        height: rem(16);
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;

        &::after {
            position: absolute;
            content: '';
            height: rem(16);
            width: 1px;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: $platinum;
        }

        ~#{$root}_input {
            padding-left: rem(56) !important;
        }
    }

    &-label {
        margin-bottom: rem(6);
    }

    &-hidden {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
    }

    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input:focus {
        outline: none;
    }
}
