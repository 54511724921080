.p-commingSoon {
    position: relative;
    overflow: hidden;
    background: url('~assets/images/comming-soon-desktop.jpg') no-repeat left bottom;
    background-size: cover;
    display: flex;
    flex-direction: column;

    @include tablet-down {
        background-image: url('~assets/images/comming-soon-tablet.jpg');
    }

    @include mobile-down {
        background-image: url('~assets/images/comming-soon-mobile.jpg');
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        @include mobile-down {
            object-position: center;
        }
    }

    &_head {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: rem(48) rem(80) 0;
        flex-shrink: 0;

        @include max-desktop-down {
            padding-top: rem(32);
        }

        @include desktop-down {
            padding-left: rem(60);
            padding-right: rem(60);
        }

        @include mobile-down {
            padding-top: rem(24);
            padding-left: rem(24);
            padding-right: rem(24);
        }
        
    }

    &_logo {
        width: 100%;
        max-width: rem(170);
        @include max-desktop-down {
            max-width: rem(120);
        }
        @include tablet-down {
            max-width: rem(100);
        }

        @include mobile-down {
            max-width: rem(78);
        }
    }

    &_text {
        @include font-base(16, 24);
        @include desktop-down {
            @include font-base(14, 22);
        }
        @include mobile-down {
            @include font-base(10, 14);
        }
    }

    &_body {
        background: url('~assets/images/comming-soon-center.png') no-repeat center;
        background-size: contain;
        flex-grow: 1;
        @include desktop-down {
            background-image: url('~assets/images/comming-soon-center-tab.png');
        }

        @include mobile-down {
            background-image: url('~assets/images/comming-soon-center-mb.png');
        }
    }

    &_disclaimer {
        flex-shrink: 0;
        width: 36%;
        height: 15.8%;
        padding-left: rem(80);
        @include desktop-down {
            padding-left: rem(60);
        }

        @include desktop-down {
            width: 54%;
            margin-left: auto;
            margin-right: auto;
            padding-left: 0;
        }

        @include mobile-down {
            width: 72%;
        }
    }
}
