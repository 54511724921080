.t-homeNewsCard {
    &_news {
        padding: 0 rem(20.5);

        @include tablet-down {
            padding: 0 rem(16);
        }
    }

    &_list {
        margin: 0 rem(-20.5);

        @include tablet-down {
            margin: 0 rem(-16);
        }
    }

    &_detailBtn {
        width: fit-content;
        background-color: $french-plum;
        padding: rem(14) rem(22);
        border-radius: rem(32);
        @include adjust-flex;
        margin-top: rem(40);
        margin-left: auto;
        margin-right: auto;

        .a-icon-nextArrow {
            margin-left: rem(10);
            transition: all 0.2s ease-in-out;
        }

        &:hover {
            .a-icon-nextArrow {
                transform: translateX(10px);
            }
        }

        @include mobile-down {
            width: 100%;
            padding-top: rem(8);
            padding-bottom: rem(8);
            margin-top: rem(24);
        }
    }

    .slick-dots {
        position: relative !important;
        bottom: 0;
    }

    .slick-active {
        button {
            background-color: $quinacridone-magenta !important;
        }
    }
}
