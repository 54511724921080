.o-learningProgress {
    $root: &;

    display: flex;
    flex-direction: column;
    width: fit-content;
    justify-content: flex-end;
    position: relative;
    transform: translate(rem(10), rem(-10));


    &_line {
        position: absolute;
        background-color: $gainsboro;
        height: rem(2);
    }

    &_bar {
        position: relative;
        bottom: 0;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
    }

    &_indicator {
        position: relative;
        height: fit-content;
        padding-top: rem(32);
        margin-bottom: rem(8);

        &:not(:last-child) {
            #{$root}_dot {
                &::before {
                    content: '';
                    position: absolute;
                    display: block;
                    width: rem(50);
                    height: rem(2);
                    background-color: $gainsboro;
                    bottom: rem(4);
                }
            }
        }

        &:last-child {
            #{$root}_dot {
                width: fit-content;
            }
        }

        &-active {
            // transform: translateY(-56px);
            margin-top: rem(56);
        }
    }

    &_location {
        position: absolute;
        opacity: 0;
        display: none;

        &-active {
            opacity: 1;
            display: block;
            top: rem(-20);
            left: rem(5);
            transform: translate(-50%, rem(-8));

            @include mobile-down {
                top: rem(-20);
            }
        }
    }

    &_dot {
        position: relative;
        height: rem(10);
        width: rem(50);

        &::after {
            content: '';
            position: absolute;
            display: block;
            width: rem(10);
            height: rem(10);
            border-radius: 50%;
            background-color: $ash-grey;
            outline: rem(1) solid $white;
            // transform: translateY(-50%);
        }

        &-completed {
            &::after {
                background-color: $oxford-blue;
                background-image: url('~assets/icons/ic_checkWhite.svg');
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        &-active {
            &::after {
                background-color: $cadmium-orange;
            }
        }
    }

    &_text {
        position: absolute;
        left: rem(5);
        font-size: rem(12);
        transform: translateX(-50%);
        color: $ash-grey;
        padding-top: rem(8);

        &-completed {
            color: $oxford-blue;
        }

        &-active {
            color: $cadmium-orange;
        }

        .tfcText {
            text-wrap: nowrap;
        }
    }
}
