.o-section {
    padding-top: rem(50);
    padding-bottom: rem(50);

    @include tablet-down {
        padding-top: rem(32);
        padding-bottom: rem(32);
    }

    @include mobile-down {
        padding-top: rem(24);
        padding-bottom: rem(24);
    }

    &.noBottom {
        padding-bottom: 0;
    }
}
