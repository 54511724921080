.p-verify {
    padding-top: rem(15);
    margin: auto;
    margin-bottom: rem(100);
    
    &_breadcrumbs {
        margin-bottom: rem(15);
        display: flex;
        justify-content: center;
    }

    &_form {
        max-width: rem(450);
        padding: rem(50) rem(60);
        border-radius: rem(30);
        background-color: $anti-flash-white;
        margin: auto;
        
        @include mobile-down {
            width: 100%;
        }
    }

    &_title {
        margin-bottom: rem(40);
    }

    &_col {
        margin-bottom: rem(15);
    }

    &_input {
        margin-bottom: rem(30);

        &_container {
            position: relative;
        }

        @include mobile-down {
            width: 100%;
        }
    }

    &_submit {
        width: rem(160);
        margin: auto;
    }

    &_error {
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translateY(rem(20));
    }

    @include mobile-down {
        &_form {
            padding: rem(16);
        }
    }
}
