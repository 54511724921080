.a-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;

    &-16 {
        height: rem(16);
        width: rem(16);
    }

    &-24 {
        height: rem(24);
        width: rem(24);
    }

    &-28 {
        height: rem(28);
        width: rem(28);
    }

    &-30 {
        height: rem(30);
        width: rem(30);
    }

    &-32 {
        height: rem(32);
        width: rem(32);
    }

    &-full {
        width: 100%;
        aspect-ratio: 1;
    }

    &-rightArrow {
        background-image: url('~assets/icons/ic_rightArrow.svg');
    }

    &-leftArrow {
        background-image: url('~assets/icons/ic_rightArrow.svg');
        transform: rotate(180deg);
    }

    &-arrowDownBound {
        background-image: url('~assets/icons/ic_arrowDownBound.svg');
    }

    &-graduation {
        background-image: url('~assets/icons/ic_graduation.svg');
    }

    &-email {
        background-image: url('~assets/icons/ic_email.svg');
    }

    &-lock {
        background-image: url('~assets/icons/ic_lock.svg');
    }

    &-user {
        background-image: url('~assets/icons/ic_user.svg');
    }

    &-phone {
        background-image: url('~assets/icons/ic_phone.svg');
    }

    &-clinic {
        background-image: url('~assets/icons/ic_clinic.svg');
    }

    &-city {
        background-image: url('~assets/icons/ic_city.svg');
    }

    &-location {
        background-image: url('~assets/icons/ic_location.svg');
    }

    &-graduate {
        background-image: url('~assets/icons/ic_graduation.svg');
    }
    
    &-close {
        background-image: url('~assets/icons/ic_close.svg');
    }

    &-resultPass {
        background-image: url('~assets/icons/ic_result_pass.svg');
    }
    
    &-resultFail {
        background-image: url('~assets/icons/ic_result_fail.svg');
    }

    &-rightArrowCircle {
        background-image: url('~assets/icons/ic_arrow_right_circle.svg');
    }
    
    &-nextArrow {
        background-image: url('~assets/icons/ic_next_arrow.svg');
    }

    &-userWhite {
        background-image: url('~assets/icons/ic_user_white.svg');
    }

    &-phoneWhite {
        background-image: url('~assets/icons/ic_phone_white.svg');
    }

    &-emailWhite {
        background-image: url('~assets/icons/ic_email_white.svg');
    }
    
    &-account {
        background-image: url('~assets/icons/ic_account.svg');
    }

    &-accountBlue {
        background-image: url('~assets/icons/ic_account-blue.svg');
    }

    &-burger {
        background-image: url('~assets/icons/ic_burger.svg');
    }

    &-lockBlue {
        background-image: url('~assets/icons/ic_lock-blue.svg');
    }

    &-history {
        background-image: url('~assets/icons/ic_history.svg');
    }

    &-logout {
        background-image: url('~assets/icons/ic_logout.svg');
    }
    
    &-userEdit {
        background-image: url('~assets/icons/ic_userEdit.svg');
    }

    &-changePicture {
        background-image: url('~assets/icons/ic_switch-camera.svg');
    }
    
    &-checkWhite {
        background-image: url('~assets/icons/ic_checkWhite.svg');
    }
}
