
.m-pulldown {
    position: relative;
    width: 100%;
    border: 1px solid $light-cyan;
    border-radius: rem(5);
    background-color: $white;

    &-label {
        margin-bottom: rem(6);
    }

    &_prefixIcon {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        padding-left: rem(12);
        padding-right: rem(12);
        border-right: 1px solid $platinum;
    }

    &-error {
        border-color: $fire-engine-red !important;
    }

    &_select {
        flex-grow: 1;

        &__value-container {
            padding-left: rem(52) !important;
        }

        &__control {
            background-color: transparent !important;
            min-height: rem(38) !important;
            border: 0 !important;
            color: $white !important;
        }

        &__menu {
            color: $black !important;
            z-index: 2 !important;
            box-shadow: 0 0 0 1px $light-cyan,0 4px 11px $light-cyan !important;

            &-list {
                max-height: rem(238) !important;
            }
        }

        &__option {
            cursor: pointer !important;

            &--is {
                &-focused {
                    background-color: $light-cyan !important;
                }
    
                &-selected {
                    background-color: $resolution-blue !important;
                }
            }
        }

    }
}
