.t-mainLayout {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    &_body {
        flex-grow: 1;
        padding-top: rem(140);

        &-minimize {
            padding-top: rem(74);
        }

        @include tablet-down {
            padding-top: rem(64);
        }
    }
}
