.o-header {
    $root: &;

    z-index: $z-header;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;

    &_top {
        display: flex;
        align-items: center;
        height: rem(40);
        background-color: $oxford-blue;
        transition: $transition ease-in-out;

        &-primary {
            background-color: $imperial-purple;
        }

        &_account {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            span {
                margin: 0 rem(2);
            }
        }

        &_accountBtnGroup {
            display: flex;
            align-items: center;
        }

        &_right {
            display: flex;
            align-items: center;
        }

        &_avatar {
            aspect-ratio: 1;
            margin-right: rem(5);
        }

        &_accountBtn {
            padding: 0;
            background: transparent;
            width: fit-content;
            height: fit-content;
        }

        &_welcome {
            display: inline-block;
        }
    }

    &_logo {
        position: relative;
        z-index: 2;
        display: block;
        width: rem(112);

        .tfcImage {
            max-width: rem(112);
            height: rem(42);
        }

        &-mobile {
            display: none;

            img {
                width: 100%;
            }
        }
    }

    &_nav {
        position: relative;
        background-color: $resolution-blue;
        padding: rem(29) 0;
        transition: $transition ease-in-out;

        &_inner {
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            display: flex;
            justify-content: center;
            transform: translateY(-60%);
        }

        &-primary {
            background-color: $french-plum;

            a {
                &::after {
                    background-color: $white !important;
                }
            }
        }

        &_link {
            position: relative;

            &:not(:last-child) {
                margin-right: rem(80);
            }

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                transform: scaleX(0);
                height: rem(4);
                bottom: rem(-8);
                left: 0;
                border-radius: rem(999);
                background-color: $cadmium-orange;
                transform-origin: bottom right;
                transition: transform 0.25s ease-out;
            }

            &:hover {
                &::after {
                    transform: scaleX(1);
                    transform-origin: bottom left;
                }
            }

            &-active {
                &::after {
                    transform: unset;
                }
            }
        }
    }

    &-minimize {
        #{$root} {
            &_top {
                height: 0;
            }

            &_nav {
                padding: rem(16) 0;
            }
        }
    }

    &_mobile {
        position: relative;
        padding: rem(12) 0;

        &_inner {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &_burger {
            width: fit-content;
            display: none;
        }

        &_nav {
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: rem(48) rem(16);

            &_wrapper {
                display: none;
                position: fixed;
                background-color: $oxford-blue;
                top: rem(64);
                width: 100%;
                height: calc(100vh - rem(64));
                transform: translateX(100%);
                transition: 0.2s;

                &-open {
                    transform: translateX(0);
                }

                &-primary {
                    background-color: $imperial-purple;

                    a {
                        &::after {
                            background-color: $white !important;
                        }
                    }
                }
            }

            &_link {
                position: relative;
                margin-top: rem(24);
                font-size: rem(18);
                width: fit-content;

                &:not(:last-child) {
                    margin-bottom: rem(20);
                }
    
                &-active {
                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: rem(4);
                        bottom: rem(-8);
                        left: 0;
                        border-radius: rem(999);
                        background-color: $cadmium-orange;
                    }
                }
            }
        }

        &_topRight {
            display: flex;
        }
    }

    &_select {
        position: relative;

        .tfcAvatar {
            width: rem(24);
        }        

        &_trigger {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 0;
            background: transparent;
            width: fit-content;
            height: fit-content;

            &:first-child {
                margin-right: rem(8);
            }
        }

        &_content {
            position: absolute;
            z-index: 1003;
            width: rem(220);
            background-color: $white;
            border-radius: rem(15);
            border: 1px solid $platinum;
            padding: rem(16);
            opacity: 0;
            pointer-events: none;
            transition: 0.2s;
            transform: translateY(20%);
            right: 0;

            ul {
                list-style-type: none;
            }

            a {
                display: inline-flex;
                align-items: center;

                .a-icon {
                    margin-right: rem(12);
                }
            }

            &-open {
                opacity: 1;
                pointer-events: unset;
                transform: translateY(rem(14));
            }

            &::after {
                content: '';
                position: absolute;
                width: rem(12);
                height: rem(12);
                background-color: $white;
                border-radius: rem(4);
                border: 1px solid $platinum;
                top: 0;
                right: rem(-20);
                clip-path: polygon(0 0, 0% 100%, 100% 0);
                transform: translate(-300%, -45%) rotate(45deg);
            }

            li {
                &:first-child {
                    display: none;
                }
            }
        }
    }

    &_account {
        &_item {
            width: 100%;
            padding: rem(8);
            display: flex;
            align-items: center;
            cursor: pointer;
            border-radius: rem(15);
            transition: 0.2s;
            height: rem(36);

            .a-icon {
                margin-right: rem(8);
            }

            &:hover {
                background-color: $platinum;
            }
        }
    }

    @include desktop-down {
        #{$root}_nav {
            &_link {
                &:not(:last-child) {
                    margin-right: rem(40);
                }
            }
        }
    }

    @include tablet-down {
        #{$root}_top {
            height: rem(64);
            background-color: $resolution-blue;

            &-primary {
                background-color: $french-plum;
            }

            &_account {
                justify-content: space-between;
            }

            &_welcome {
                display: none;
            }
        }

        #{$root}_logo {
            &-mobile {
                display: flex;
                align-items: center;
                max-width: rem(96);
            }
        }

        #{$root}_nav {
            display: none;

            &_link {
                display: none;
            }
        }

        #{$root}_mobile {
            &_burger {
                position: relative;
                display: block;
                width: rem(48);
                height: rem(48);

                span {
                    display: block;
                    width: rem(24);
                    height: 2px;
                    border-radius: 1px;
                    background-color: $white;
                    margin-top: rem(5);
                    transition: all $transition ease-in-out;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                &-open {
                    span {
                        &:first-child {
                            position: absolute;
                            top: 50%;
                            left: 46%;
                            transform: translate(-50%, -50%) rotate(45deg);
                        }

                        &:nth-child(2) {
                            display: none;
                        }

                        &:last-child {
                            position: absolute;
                            top: 40%;
                            left: 45%;
                            transform: translate(-50%, -50%) rotate(-45deg);
                        }
                    }
                }
            }

            &_nav {
                &_wrapper {
                    display: block;
                }
            }
        }

        #{$root}_select {
            .tfcAvatar {
                width: rem(36);
            }

            &_content {
                padding: rem(15);

                li {
                    &:first-child {
                        display: block;
                        margin-bottom: rem(8);
                    }
                }
            }
        }
    }
}
