.a-button {
    position: relative;
    background-color: $resolution-blue;
    color: $white;
    border-radius: rem(58);
    font-weight: 600;
    font-size: rem(16);
    font-style: normal;
    padding: rem(12) rem(24);
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: rem(48);
    width: 100%;

    &-primary {
        background-color: $resolution-blue-auth;
    }

    &-sm {
        padding: rem(8) rem(30);
        height: rem(40);
    }

    &-leftIcon {
        flex-direction: row-reverse;
    }

    &-solid {
        &:disabled {
            background-color: $platinum;
        }
    }

    &-outline {
        background-color: transparent;
        border: rem(2) solid $resolution-blue;
        color: $resolution-blue;

        &:disabled {
            border: rem(2) solid $platinum;
            color: $platinum;
        }
    }

    &-thinOutline {
        background-color: transparent;
        border: rem(1) solid $spanish-gray;
        color: $raisin-black;
        font-weight: 500;
        font-size: rem(14);

        &:disabled {
            border: rem(2) solid $platinum;
            color: $platinum;
        }
    }

    &-iconOnly {
        border-color: transparent;
        background: transparent;
        padding: rem(8);
    }

    .loadingDashed {
        &::before,
        &::after {
            width: auto;
            height: rem(20);
            aspect-ratio: 1;
        }
    }

    .tfcbtn-icon {
        margin-top: 0;
    }

    &-widthFit {
        width: fit-content;
    }

    &-uppercase {
        text-transform: uppercase !important;
    }

    &-lowercase {
        text-transform: lowercase !important;
    }

    &:hover {
        opacity: 0.8;
        transition: 0.25s all ease;
    }

    &:disabled {
        pointer-events: none;
    }
}
