// Text Colors
$white: rgb(255, 255, 255); // #ffffff
$black: rgb(0, 0, 0); // #000000
$raisin-black: rgb(34, 34, 34); //#222222
$davys-grey: rgb(89, 89, 89); //#595959
$gray: rgb(126, 126, 126); //#7e7e7e
$spanish-gray: rgb(145, 145, 145); //#919191
$platinum: rgb(231, 231, 231); // #e7e7e7
$isabelline: rgb(237, 237, 237); //#ededed
$white-smoke: rgb(244, 246, 250); //#f4f6fa
$fire-engine-red: rgb(198, 30, 30); //#c61e1e
$prussian-blue: rgb(6, 43, 75); //#062b4b
$light-cyan: rgb(219, 244, 255); //#dbf4ff
$resolution-blue: rgb(1, 26, 127); //#011a7f
$resolution-blue-auth: rgb(130, 0, 82); //#820052
$battleship-grey: rgb(130, 130, 130); //#828282
$red-pigment: rgb(236, 31, 40); // #ec1f28
$oxford-blue: rgb(0, 19, 91); //#00135b
$oxford-blue-auth: rgb(91, 18, 62); //#5b123e
$anti-flash-white: rgb(241, 245, 247); //#f1f5f7
$resolution-blue-2: rgb(0, 27, 129); // #001b81
$international-orange: rgb(191, 40, 40); //#bf2828
$dim-gray: rgb(12, 11, 11); //#696969
$dim-gray: rgb(105, 105, 105); //#696969
$forest-green: rgb(44, 165, 57); //#2ca539
$isabelline: rgb(236, 236, 236); //#ececec
$light-gray: rgb(208, 208, 208); // #d0d0d0
$gainsboro: rgb(217, 217, 217); // #d9d9d9
$cadmium-orange: rgb(245, 135, 46); // #f5872e
$isabelline: rgb(236, 236, 236); //#ececec
$azureish-white: rgb(213, 226, 232); //#d5e2e8

$dark-gray-x11: rgb(167, 167, 167); //#a7a7a
$forest-green: rgb(44, 165, 57); //#2ca539
$golden-gate-bridge: rgb(191, 40, 40); //#bf2828
$timberwolf: rgb(216, 216, 216); //#d8d8d8
$white-smoke: rgb(248, 248, 248); //#f8f8f8
$french-plum: rgb(130, 0, 82); //#820052
$marigold: rgb(229, 163, 51); //#e5a333
$jet: rgb(54, 54, 54); //#363636
$magenta: rgb(151, 49, 97); //#973161
$quinacridone-magenta: rgb(151, 49, 97); //#973161
$jet: rgb(54, 54, 54); //#363636
$magenta: rgb(151, 49, 97); //#973161
$french-plum: rgb(130, 0, 82); //#820052
$smoky-black: rgb(15, 15, 15); //#0f0f0f
$bubbles: rgb(233, 248, 255); //#e9f8ff
$light-blue: rgb(168, 213, 234); //#a8d5ea
$outer-space: rgb(70, 70, 70); //#464646
$diamond: rgb(196, 230, 246); //#c4e6f6
$dark-electric-blue: rgb(97, 104, 132); //#616884
$bubbles: rgb(233, 248, 255); //#e9f8ff
$imperial-purple: rgb(91, 18, 62); //#5b123e
$onyx: rgb(56, 56, 56); //#383838
$anti-flash-white2: rgb(240, 240, 240); //#f0f0f0
$gainsboro: rgb(217, 217, 217); //#d9d9d9
$ash-grey: rgb(182, 182, 182); //#b6b6b6

// Overlays
$black-088: rgba($black, 0.88);
$white-07: rgba($white, 0.7);
$raisin-black-07: rgba($raisin-black, 0.7);
$raisin-black-08: rgba($raisin-black, 0.8);
$gainsboro-05: rgba($gainsboro, 0.5);

// Linears
$linear-about-section: linear-gradient(278.31deg, #e9f8ff 59.56%, #94dcff 97.47%);
