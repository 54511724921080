.o-examControls {
    width: 100%;
    max-width: rem(600);
    margin-left: auto;
    margin-right: auto;

    &_topControl {
        @include adjust-flex;
    }

    &_arrowWrap {
        width: rem(20);
        height: rem(20);
        background-color: $resolution-blue;
        border-radius: rem(6);
        cursor: pointer;
        position: relative;
        flex-shrink: 0;

        &:hover {
            opacity: 0.7;
        }

        &-disabled {
            background-color: $light-gray;
            pointer-events: none;
        }
    }

    &_current {
        color: $cadmium-orange;
        width: 22px;
        display: inline-block;
        text-align: right;
        margin-right: rem(2);
        @include mobile-down {
            width: rem(18);
        }
    }
    
    &_textControl {
        margin-left: rem(100);
        margin-right: rem(100);
    }

    &_leftArrow,
    &_rightArrow {
        position: absolute;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        background-image: url('~assets/icons/ic_arrow_left.svg');
        height: rem(18);
        width: rem(18);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        flex-shrink: 0;
    }

    &_rightArrow {
        background-image: url('~assets/icons/ic_arrow_right.svg');
    }

    &_progress {
        margin-top: rem(16);
        position: relative;
        height: rem(4);
    }

    &_progressBar {
        background: $gainsboro;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-50%);
        margin: 0;
        border-radius: rem(16);
    }

    &_progressValue {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background: $resolution-blue;
        transform: translateY(-50%);
        border-radius: rem(16);
        transition: $transition ease-in-out;
    }
}
