.o-modal {
    $root: &;
    height: 100%;
    outline: 0;
    position: relative;
    width: 100%;

    &:focus {
        outline: 0;
    }

    &_close {
        position: absolute;
        width: fit-content;
        height: fit-content;
        top: rem(15);
        right: rem(15);
    }

    &_title {
        padding: rem(8) rem(40);

        &-xl {
            padding: rem(8) rem(48);
        }
    }

    &-notify {
        max-width: rem(380);
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    &_main {
        align-items: center;
        display: flex;
        height: 100%;
        max-width: rem(1366);
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
        padding: rem(12);
        width: 100%;

        &-sm {
            max-width: rem(360);
        }

        &-md {
            max-width: rem(430);
        }

        &-lg {
            max-width: rem(790);
        }
    }

    &_wrapper {
        border-radius: rem(15);
        background-color: $white;
        color: $black;
        display: flex;
        flex-direction: column;
        padding: rem(17) rem(17) rem(25) rem(17);
        overflow: hidden;
        position: relative;
        width: 100%;
        z-index: 103;

        &-sharedFolder {
            max-width: rem(580);
            margin-left: auto;
            margin-right: auto;
        }
    }

    &-success {
        #{$root}_wrapper {
            padding: 0;
            border-radius: rem(27);
        }
    }

    &-control {
        .o-modal {
            &_wrapper {
                width: 60%;
                margin-left: auto;
                margin-right: auto;
                padding: rem(26) rem(32);
            }
        }
    }

    &-propmt {
        #{$root}_main {
            max-width: rem(546);
            
            #{$root}_wrapper {
                border-radius: rem(20);
            }
        }
    }

    &-updateProfile {
        #{$root}_title {
            @include mobile-down {
                padding: 0 !important;
                @include font-base(16, 24);
                margin-top: rem(6);
            }
        }

        #{$root}_body {
            @include mobile-down {
                padding: 0 !important;
            }
        }
    }

    &_body {
        flex: 1;
        padding: rem(30) rem(32) 0;
        max-height: 76vh;
        overflow: hidden auto;

        @include iphone-promax-lands {
            max-height: 68vh;
        }
    }
}

.ReactModal__Overlay {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0);
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateX(-100px);
}

.ReactModal__Overlay {
    background: rgba(34, 34, 34, 0.3);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: fixed;
    transform: translateY(-100%);
    transition: all 500ms ease-in-out;
    z-index: $z-modal;

    &::after {
        background: rgba(34, 34, 34, 0.3);
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 102;
    }

    &--open {
        overflow: hidden;
    }

    &--after-open {
        opacity: 1;
        transform: translateY(0);
    }

    &--before-close {
        opacity: 0;
        transform: translateY(-100%);
    }
}
