html,
body {
    font-family: 'SVN-Poppins', sans-serif;
    font-size: 16px;
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent; //issue click in mobile flash background highligh
    @include scroll-bars(4);
}

button {
    font-family: inherit;

    &:focus {
        outline: none;
    }
}

p {
    margin-bottom: 0;
    margin-top: 0;
    -webkit-text-size-adjust: 100%;
}

a {
    text-decoration: none;
}

[role='button'],
button {
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

input {
    word-break: normal;
    font-family: inherit;

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
        transition: background-color 0s 600000s, color 0s 600000s !important;
    }
}

ul,
ol {
    margin: 0;
    padding: 0;
}

.hidden-animation {
    visibility: hidden;
}

.overflow-hidden {
    height: 100vh;
}

.u-mt {
    &-2 {
        margin-top: rem(2);
    }

    &-24 {
        margin-top: rem(24);
    }

    &-16 {
        margin-top: rem(16);
    }
}

.fs {
    @include style-font-base();
}

.color {
    @include style-color();
}

.title-section {
    margin-bottom: rem(40);
    @include tablet-down {
        margin-bottom: rem(28);
    }
    @include mobile-down {
        margin-bottom: rem(20);
    }
}

.loading-fullscreen {
    height: 100vh;
    left: 0;
    margin: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}
