.p-register {
    padding-top: rem(15);
    
    &_form {
        background-color: $anti-flash-white;
        border-radius: rem(30);
        padding: rem(50) rem(60);
        margin-bottom: rem(100);

        @include mobile-down {
            padding: rem(24);
        }

        &_col {
            padding: 0 rem(20);

            @include tablet-down {
                padding: 0 rem(8);
            }

            @include mobile-down {
                padding: 0;
            }
        }

        &_header {
            @include adjust-flex(space-between);
            flex-wrap: wrap;

            &_item {
                flex-basis: calc(100% / 3);

                @include tablet-down {
                    flex-basis: 100%;
                }

                &.returnLoginText {
                    @include tablet-down {
                        margin-top: rem(8);
                        text-align: center;
                    }
                }
            }
        }

        &_inner {
            margin-top: rem(40);
            margin-right: calc(-0.5 * rem(40));
            margin-left: calc(-0.5 * rem(40));

            @include mobile-down {
                margin-top: rem(24);
                margin-right: calc(-0.5 * rem(16));
                margin-left: calc(-0.5 * rem(16));
            }
        }
    }

    &_field {
        margin-top: rem(15);
    }

    &_title {
        @include mobile-down {
            margin-top: rem(24);
        }
    }

    &_checkbox {
        font-size: rem(12);

        label {
            justify-content: center;
        }
    }

    &_checkboxGroup {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        flex-direction: column;
        margin-top: rem(30);

        &_field {
            width: fit-content;

            &:last-child {
                margin-top: rem(10);
            }
        }

        @include mobile-down {
            margin-right: unset;
            margin-left: unset;
        }
    }

    &_submit {
        margin-top: rem(30);
        max-width: rem(220);
        margin-left: auto;
        margin-right: auto;

        @include mobile-down {
            max-width: 100%;
        }
    }

    &_checkboxWrap {
        display: flex;
        align-items: center;
        font-size: rem(12);
    }
}
