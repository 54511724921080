.t-howToJoin {
    $root: &;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    background-color: $bubbles;
    padding: rem(118) rem(137);
    border-radius: rem(30);

    &_item {
        // $item: &;

        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;

        &_step {
            position: relative;
            padding: rem(0) rem(16) rem(15) rem(16);
            border-bottom: rem(2) solid $dark-electric-blue;

            &::after {
                content: '';
                display: block;
                position: absolute;
                aspect-ratio: 1;
                width: rem(12);
                height: rem(12);
                border-radius: rem(999);
                background-color: $cadmium-orange;
                bottom: 0;
                left: 0;
                transform: translate(-50%, 50%);
            }

            &::before {
                content: '';
                display: block;
                position: absolute;
                aspect-ratio: 1;
                width: rem(12);
                height: rem(12);
                border-radius: rem(999);
                background-color: $cadmium-orange;
                bottom: 0;
                right: 0;
                transform: translate(50%, 50%);
            }
        }

        &_main {
            padding: rem(16) rem(16) 0 rem(16);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            &_top {
                flex: 1;
                margin-bottom: rem(20);
            }
        }

        &_title {
            margin-bottom: rem(10);
        }

        &_content {
            flex: 1;
        }

        &_image {
            aspect-ratio: 258/196;
            width: 100%;
            height: auto;
            background-color: $white;
            overflow: hidden;
            border-radius: rem(15);
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            img {
                position: absolute;
                aspect-ratio: 1;
                width: rem(120);
                height: auto;
            }
        }
    }

    @include desktop-down {
        padding: rem(64) rem(32);

        #{$root}_item {
            &_image {
                img {
                    width: rem(100);
                }
            }
        }
    }

    @include mobile-down {
        display: flex;
        flex-direction: column;
        padding: rem(20);

        #{$root}_item {
            padding-bottom: rem(40);
            border-left: rem(2) solid $dark-electric-blue;

            &:first-child {
                border-left: unset;

                &::before {
                    content: '';
                    position: absolute;
                    margin-left: rem(1);
                    width: rem(2);
                    height: 100%;
                    background-color: $dark-electric-blue;
                    top: rem(12);
                    left: 0;
                    transform: translateX(-50%);
                }

                #{$root}_item_step {
                    &::after {
                        left: rem(-5);
                    }
                }
            }

            &:last-child {
                padding-bottom: 0;
            }

            &_step {
                border-bottom: unset;
                padding: rem(0) rem(16) rem(8) rem(16);

                &::after {
                    top: 50%;
                    left: rem(-7);
                    transform: translateY(-50%);
                }

                &::before {
                    display: none;
                }
            }

            &_main {
                padding: rem(8) rem(16) 0 rem(16);
            }

            &_image {
                width: 70%;

                img {
                    width: rem(120);
                }
            }
        }
    }

    &_heading {
        margin-top: rem(50);
        margin-bottom: rem(50);

        @include mobile-down {
            margin-top: rem(24);
            margin-bottom: rem(24);
        }
    }
}
