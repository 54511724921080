.t-profileCard {
    $root: &;

    position: relative;
    background: $anti-flash-white;
    border-radius: rem(15);
    padding: rem(20) rem(30) rem(30) rem(30);

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: rem(40);
        border-top-left-radius: rem(15);
        border-top-right-radius: rem(15);
        background-color: $cadmium-orange;
        background-image: url('~assets/images/heartbeat.svg');
        background-repeat: no-repeat;
        background-position: bottom right;
    }

    &_top {
        display: flex;
        align-items: center;
        padding-bottom: rem(25);
        margin-bottom: rem(25);
        border-bottom: 1px solid $isabelline;

        &_name {
            margin-left: rem(15);
        }

        &_avatar {
            $avatar: &;

            position: relative;
            border-radius: rem(999);
            overflow: hidden;

            &_edit {
                cursor: pointer;
                position: absolute;
                z-index: 100;
                width: 100%;
                height: 50%;
                bottom: -50%;
                background: #0006;
                transition: 0.2s;
                display: flex;
                justify-content: center;
                padding-top: rem(2);
            }

            &:hover {
                #{$avatar}_edit {
                    transform: translateY(-60%);
                }
            }
        }
    }

    &_edit {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: rem(16);

        &_btn {
            width: fit-content;
            height: fit-content;
        }
    }

    &_info {
        display: flex;

        &:not(:last-child) {
            margin-bottom: rem(20);
        }

        .tfcText {
            &:first-child {
                min-width: rem(128);
                margin-right: rem(23);
            }
        }

        &_wrapper {
            padding-bottom: rem(25);
            margin-bottom: rem(25);
            border-bottom: 1px solid $isabelline;
            word-break: break-word;
        }
    }

    &_actions {
        display: flex;
        align-items: center;
        justify-content: center;

        .a-button {
            width: rem(180);

            &:first-child {
                margin-right: rem(25);
            }
        }
    }

    @include small-mobile {
        padding: rem(20);

        &_actions {
            .a-button {
                &:first-child {
                    margin-right: rem(16);
                }

                margin-bottom: rem(16);
                width: 100%;
            }
        }
    }
};
