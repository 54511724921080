.o-banner {
    height: calc(var(--min-height) - 140px);
    transition: $transition ease-in-out;
    
    @include tablet-down {
        height: calc(var(--min-height) - 64px);
    }

    &_image {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        img {
            display: none;
        }
    }
}
