@use 'sass:math';

@mixin text-overflow ($number: 2) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $number;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin aspect-ratio($width: 1, $height: 1) {
    position: relative;

    &::before {
        content: '';
        display: block;
        padding-bottom: math.div($height, $width) * 100%;
    }
}

@mixin transition($value) {
    transition: $value;
}

@mixin scroll-bars($size: 12, $thumb-color: $timberwolf, $track-color: transparent, $border-radius-thumb: 5, $border-thumb: 2) {

    // For Internet Explorer

    scrollbar-face-color: $thumb-color;
    scrollbar-track-color: $track-color;

    // For Google Chrome
    &::-webkit-scrollbar {
        height: rem($size);
        margin-right: rem(10);
        width: rem($size);
    }

    &::-webkit-scrollbar-thumb {
        background: $thumb-color;
        border: rem($border-thumb) solid $track-color;
        border-radius: rem($border-radius-thumb);
    }

    &::-webkit-scrollbar-track {
        background: $track-color;
    }
}

@mixin font-base($font-size: 16, $line-height: 24) {
    font-size: rem($font-size);
    line-height: rem($line-height);
}

@mixin style-bg-color() {
    &-bg-white {
        background-color: $white;
    }
}

@mixin style-font-weight() {
    &-100 {
        font-weight: 100;
    }

    &-200 {
        font-weight: 200;
    }

    &-300 {
        font-weight: 300;
    }

    &-400 {
        font-weight: normal;
    }

    &-500 {
        font-weight: 500;
    }

    &-600 {
        font-weight: 600;
    }

    &-700 {
        font-weight: bold;
    }

    &-800 {
        font-weight: 800;
    }

    &-900 {
        font-weight: 900;
    }
}

@mixin style-general() {
    &-uppercase {
        text-transform: uppercase;
    }

    &-capitalize {
        text-transform: capitalize;
    }

    &-underline {
        text-decoration: underline;
    }

    &-italic {
        font-style: italic;
    }

    &-center {
        text-align: center;
    }

    &-justify {
        text-align: justify;
    }
}

@mixin reset-button {
    appearance: none;
    background-color: none;
    border: 0;
    outline: none;
}

@mixin list-style-none {
    list-style: none;
    margin: 0;
    padding: 0;
}

@mixin adjust-flex($justify-content: center, $align-item: center) {
    align-items: $align-item;
    display: flex;
    justify-content: $justify-content;
}

$colors-design: (
    'white': $white,
    'black': $black,
    'resolutionBlue': $resolution-blue,
    'oxfordBlue': $oxford-blue,
    'resolutionBlueAuth': $resolution-blue-auth,
    'oxfordBlueAuth': $oxford-blue-auth,
    'magenta': $magenta,
    'quinacridoneMagenta': $quinacridone-magenta,
    'cadmiumOrange': $cadmium-orange,
    'gray': $gray,
    'jet': $jet,
    'onyx': $onyx,
);

@mixin style-color {
    @each $name, $color in $colors-design {
        &-#{$name} {
            color: $color;
        }
    }
}
@mixin style-font-base() {
    &-12x18 {
        @include font-base(12, 18);
    }

    &-13x18 {
        @include font-base(13, 18);
        @include mobile-down {
            @include font-base(11, 15);
        }
    }

    &-14x17 {
        @include font-base(14, 17);

        @include mobile-down {
            @include font-base(12, 18);
        }
    }

    &-14x21 {
        @include font-base(14, 21);

        @include mobile-down {
            @include font-base(12, 18);
        }
    }

    &-14x32 {
        @include font-base(14, 32);

        @include mobile-down {
            @include font-base(12, 18);
        }
    }

    &-16x18 {
        @include font-base(16, 18);

        @include mobile-down {
            @include font-base(14, 16);
        }
    }

    &-16x24 {
        @include font-base(16, 24);

        @include mobile-down {
            @include font-base(14, 18);
        }
    }

    &-18x28 {
        @include font-base(18, 28);

        @include tablet-down {
            @include font-base(14, 20);
        }
    }

    &-16x40 {
        @include font-base(16, 40);

        @include mobile-down {
            @include font-base(14, 24);
        }
    }

    &-20x31 {
        @include font-base(20, 31);

        @include mobile-down {
            @include font-base(16, 21);
        }
    }

    &-24x32 {
        @include font-base(24, 32);

        @include mobile-down {
            @include font-base(18, 24);
        }
    }

    &-24x38 {
        @include font-base(24, 38);

        @include mobile-down {
            @include font-base(18, 24);
        }
    }

    &-25x30 {
        @include font-base(25, 30);

        @include mobile-down {
            @include font-base(18, 24);
        }
    }

    &-28x44 {
        @include font-base(28, 44);

        @include tablet-down {
            @include font-base(20, 28);
        }
    }

    &-32x50 {
        @include font-base(32, 50);

        @include tablet-down {
            @include font-base(28, 36);
        }

        @include mobile-down {
            @include font-base(24, 32);
        }
    }

    &-32x56 {
        @include font-base(32, 56);

        @include tablet-down {
            @include font-base(28, 36);
        }

        @include mobile-down {
            @include font-base(24, 32);
        }
    }

    &-80x124 {
        @include font-base(80, 124);

        @include tablet-down {
            @include font-base(62, 86);
        }

        @include mobile-down {
            @include font-base(42, 60);
        }
    }
}
