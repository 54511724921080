.p-profile {
    padding-top: rem(15);
    padding-bottom: rem(100);

    &_inner {
        .row {
            margin-right: calc(-0.5 * rem(16));
            margin-left: calc(-0.5 * rem(16));
        }

        &_col {
            padding-left: rem(8) !important;
            padding-right: rem(8) !important;
        }
    }

    &_modal {
        &_dialog {
            text-wrap: nowrap;
            margin-bottom: rem(20);
        }
    }

    &_breadcrumbs {
        margin-bottom: rem(15);
        display: flex;
        justify-content: center;
    }

    &_title {
        padding-top: rem(20);
        margin-bottom: rem(20);
    }

    &_learningProgress {
        background-color: $white;
        border-radius: rem(15);
        padding: rem(30);
        width: 100%;
        min-height: rem(320);

        &_title {
            border-bottom: rem(1) solid $isabelline;
            padding-bottom: rem(15);
        }

        &_placeholder {
            max-width: rem(163);
            margin: rem(87) auto 0 auto;
        }

        &_wrapper {
            margin-top: rem(15);
        }

        &_item {
            margin-bottom: rem(25);
        }

        @include mobile-down {
            padding: rem(20);
        }
    }

    &_history {
        background-color: $anti-flash-white;
        border-radius: rem(15);
        padding: rem(30);

        @include mobile-down {
            padding: rem(36) rem(20);
        }
    }

    &_field {
        margin-bottom: rem(25);

        .m-pulldown_select__value-container {
            padding-left: rem(15) !important;
        }

        .m-pulldown_select__single-value {
            font-weight: 600;
            color: $resolution-blue;
        }

        @include mobile-down {
            margin-bottom: rem(15);
        }
    }

    &_logoutBtn {
        width: fit-content;
        margin: auto;
    }
}
