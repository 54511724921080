.p-contact {
    @include adjust-flex;
    flex-direction: column;
    padding: rem(16);

    &_heading {
        margin-top: rem(24);

        @include mobile-down {
            text-align: center;
        }
    }

    &_organization {
        margin-top: rem(52);
        padding-bottom: rem(16);
        position: relative;

        &::after {
            position: absolute;
            content: '';
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 140%;
            height: 1px;
            background-color: $anti-flash-white2;
        }

        &_image {
            object-fit: contain;
            width: 100%;
            max-width: rem(120);
        }

        @include mobile-down {
            margin-top: rem(16);
        }

        @include small-mobile {
            padding-bottom: rem(12);
        }
    }

    &_information {
        @include adjust-flex;
        margin-top: rem(32);

        &_detail {
            margin-left: rem(32);

            @include mobile-down {
                margin-left: rem(16);
            }
        }

        &_item {
            display: flex;
            align-items: center;

            &:not(:first-child) {
                margin-top: rem(10);
            }
        }

        &_name {
            margin-left: rem(10);
        }

        @include mobile-down {
            margin-top: rem(24);
        }
    }
}
