.a-paper {
    border-radius: rem(30);
    background-color: $anti-flash-white;
    padding: rem(50);
    width: fit-content;

    @include mobile-down {
        padding: rem(16);
    }
}
