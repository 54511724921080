.o-examCard {
    &_answers {
        margin-top: rem(25);
        padding: rem(25) 0 rem(25) rem(50);
        border-top: 1px solid $platinum;

        @include tablet-down {
            margin-top: rem(20);
            padding: rem(20) 0 rem(20) rem(30);
        }

        @include mobile-down {
            margin-top: rem(16);
            padding: rem(16) 0 rem(16) rem(16);
        }

        &_list {
            margin-top: rem(26);
        }

        &_item {
            margin-top: rem(22);
            
            &:first-child {
                margin-top: 0;
            }
        }

        &_explain {
            margin-top: rem(12);
        }
    }

    &_question {
        @include adjust-flex(flex-start, flex-start);

        &_index {
            position: relative;
            width: rem(40);
            height: rem(40);
            aspect-ratio: 1;
            background-color: $cadmium-orange;
            border-radius: 50%;
            flex-shrink: 0;

            @include tablet-down {
                width: rem(36);
                height: rem(36);
            }
    
            @include mobile-down {
                width: rem(32);
                height: rem(32);
            }

            p {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &_content {
            margin-left: rem(10);
            margin-top: rem(8);
            @include tablet-down {
                margin-top: rem(6);
            }
        }
    }

    &_answerCard {
        $root: &;
        cursor: pointer;
        @include adjust-flex(flex-start, flex-start);

        &-selected {
            #{$root} {
                &_index {
                    border-color: $resolution-blue;

                    p {
                        color: $white;
                        line-height: rem(24);
                    }

                    &::before {
                        background-color: $resolution-blue;
                    }
                }
            }
        }

        &-correct {
            #{$root} {
                &_index {
                    border-color: $forest-green;

                    p {
                        color: $white;
                        line-height: rem(24);
                    }

                    &::before {
                        background-color: $forest-green;
                    }
                }
            }
        }

        &-wrong {
            #{$root} {
                &_index {
                    border-color: $golden-gate-bridge;

                    p {
                        color: $white;
                        line-height: rem(24);
                    }

                    &::before {
                        background-color: $golden-gate-bridge;
                    }
                }
            }
        }

        &_index {
            position: relative;
            width: rem(24);
            height: rem(24);
            aspect-ratio: 1;
            border: 1px solid $dark-gray-x11;
            border-radius: 50%;
            transition: border-color $transition ease-in-out;
            flex-shrink: 0;

            p {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: $dark-gray-x11;
                transition: color $transition ease-in-out;

            }

            &::before {
                content: '';
                position: absolute;
                width: calc(100% - 2px);
                aspect-ratio: 1;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: transparent;
                border-radius: 50%;
                transition: background-color $transition ease-in-out;

            }
        }

        &_content {
            margin-left: rem(12);
        }
    }
}
