.o-ytPlayer {
    position: relative;
    width: 100%;
    @include aspect-ratio(16, 9);
    border-radius: rem(20);
    overflow: hidden;

    &_player {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: contain;
    }
}
